import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./styles.scss";
import CoreStrings from "../../../_core/strings/strings";
import SeityLoader from "../../../_core/components/SeityLoader";
import { RootState } from "../../../app/rootReducer";
import NewPersonalListModal from "./NewPersonalListModal";
import { useCVDirectoryData } from "../hooks";
import CVListItem from "./ListItem";
import ToolTipModal from "./ToolTipModal";
import MoreInfo from "../../../assets/web-icons/More_info_icon.png";
import "./styles.scss";
import { isPlotlineInjectionCompleted } from "../../plotline/helpers";
import { PlotlineInjectionPointID } from "../../../api/plotlineTypes";

const CVList: FunctionComponent = (): ReactElement => {
  const history = useHistory();

  const { personalList, hasFamilySharingFullAccess, modalTextObject, isLoading } = useCVDirectoryData();
  const { fsGroupList } = useSelector((state: RootState) => state.familySharingSlice);
  const { userAppSettings } = useSelector((state: RootState) => state.userAppSettings);
  const [showNewPLModal, setShowNewPLModal] = useState(false);
  const [showTooltipModal, setShowToolTipModal] = useState<string | null>(null);
  let toolTipDefinition = "";
  const { plotlineInjections } = useSelector((state: RootState) => {
    return state.plotline;
  });

  switch (showTooltipModal) {
    case "family":
      toolTipDefinition = `<div class="toolTipModal-definition">${modalTextObject?.coreValueDirectoryFamilyDescription}</div>`;
      break;

    case "personal":
      toolTipDefinition = `<div class="toolTipModal-definition">${modalTextObject?.coreValueDirectoryPersonalDescription}</div>`;
      break;

    case "organization":
      toolTipDefinition = `<div class="toolTipModal-definition">${modalTextObject?.coreValueDirectoryOrganizationDescription}</div>`;
      break;

    case "cvd":
      toolTipDefinition = `
        <h2 class="toolTipModal-header">${modalTextObject?.coreValueDirectoryModalHeader}</h2>
        <div class="toolTipModal-definition">${modalTextObject?.coreValueDirectoryModalContent}</div>
        <div class="toolTipModal-definition">${modalTextObject?.coreValueDirectoryFamilyDescription}</div>
        <div class="toolTipModal-definition">${modalTextObject?.coreValueDirectoryPersonalDescription}</div>
        <div class="toolTipModal-definition">${modalTextObject?.coreValueDirectoryOrganizationDescription}</div>`;
      break;

    default:
      toolTipDefinition = "";
      break;
  }

  useEffect(() => {
    if (!isPlotlineInjectionCompleted(plotlineInjections, PlotlineInjectionPointID.CVDirectory)) {
      history.push("/plotline/" + PlotlineInjectionPointID.CVDirectory);
    }
  }, []);

  return (
    <div className="cvList">
      {isLoading ? (
        <SeityLoader />
      ) : (
        <>
          <div className="list-header-cvd">
            <button
              className="title-tooltip-cvd"
              onClick={() =>
                history.push({
                  pathname: "/plotline/" + PlotlineInjectionPointID.CVDirectory,
                  state: {
                    onDemand: true
                  }
                })
              }
            >
              <img className="more-info-cvd" src={MoreInfo} />
            </button>
          </div>
          {/* Family List */}
          {userAppSettings.familySharingCVDirectoryEnabled ? (
            <>
              {!userAppSettings.familySharingPrimaryAccount && fsGroupList.length === 0 ? null : (
                <div className="list-header first">
                  <h1 className="list-title">{modalTextObject?.coreValueDirectoryFamilyHeader} </h1>
                  <button className="title-tooltip" onClick={() => setShowToolTipModal("family")}>
                    <img className="more-info" src={MoreInfo} />
                  </button>
                </div>
              )}

              <div className="cvList-list">
                {fsGroupList.length > 0 ? (
                  <>
                    {fsGroupList.map((g, index) => {
                      return (
                        <CVListItem
                          key={`family-${index}`}
                          keyIdx={`family-${index}`}
                          profilePicture={g.profilePicture}
                          title={g.name}
                          subTitle={g.isOrganizer ? CoreStrings.organizer : CoreStrings.member}
                          onClick={() => {
                            history.push({
                              pathname: "/familyGroups/" + g.familySharingID,
                              state: {
                                showInvite: false,
                                isOrganizer: g.isOrganizer
                              }
                            });
                          }}
                        />
                      );
                    })}
                  </>
                ) : (
                  <>
                    {userAppSettings.familySharingPrimaryAccount ? (
                      <div className="cvList-noDesc">
                        <h5>{CoreStrings.noFamilyDesc}</h5>
                        <button
                          onClick={() => {
                            history.push("/familyGroups");
                          }}
                        >
                          {CoreStrings.familySharing}
                        </button>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </>
          ) : null}

          {/* Personal List */}
          {userAppSettings.personalListEnabled && userAppSettings.coreValueDirectoryEnabled ? (
            <>
              <div className="list-header">
                <h1 className="list-title">{modalTextObject?.coreValueDirectoryPersonalHeader} </h1>
                <button className="title-tooltip" onClick={() => setShowToolTipModal("personal")}>
                  <img className="more-info" src={MoreInfo} />
                </button>
              </div>
              <div className="cvList-list">
                {personalList.length < 1 ? (
                  <div className="cvList-noDesc">
                    <h5>{CoreStrings.noPersonalDesc}</h5>
                    <button
                      onClick={() => {
                        setShowNewPLModal(true);
                      }}
                    >
                      {CoreStrings.createPersonalList}
                    </button>
                  </div>
                ) : (
                  <>
                    {personalList.map((listItem, index) => {
                      return (
                        <CVListItem
                          key={`personal-${index}`}
                          profilePicture={listItem.image}
                          keyIdx={`personal-${index}`}
                          title={listItem.personalListName}
                          subTitle={`${listItem.totalMembers.toString()} ${CoreStrings.member}${listItem.totalMembers == 1 ? "" : "s"}`}
                          onClick={() => history.push(`/personalList/${listItem.personalListID}`)}
                          isPersonalList={true}
                        />
                      );
                    })}
                  </>
                )}
              </div>
            </>
          ) : null}

          {/* Organization List */}
          {userAppSettings.coreValueDirectoryEnabled ? (
            <>
              <div className="list-header">
                <h1 className="list-title">{modalTextObject?.coreValueDirectoryOrganizationHeader} </h1>
                <button className="title-tooltip" onClick={() => setShowToolTipModal("organization")}>
                  <img className="more-info" src={MoreInfo} />
                </button>
              </div>
              <div className="cvList-list">
                <CVListItem
                  keyIdx={"organization"}
                  profilePicture={userAppSettings.organizationInformation.organizationLogo}
                  initials={userAppSettings.organizationInformation.organizationInitials}
                  title={userAppSettings.organizationInformation.organizationName}
                  subTitle={`${userAppSettings.organizationInformation.memberCount.toString()}  ${CoreStrings.member}${
                    userAppSettings.organizationInformation.memberCount == 1 ? "" : "s"
                  }`}
                  onClick={() => {
                    history.push({
                      pathname: "/directory"
                    });
                  }}
                />
              </div>
            </>
          ) : null}

          <button
            className="cvList-add"
            onClick={() => {
              setShowNewPLModal(true);
            }}
          >
            <img src={require("../../../assets/graphics/intentions/intentions-plus.png").default} />
          </button>
          {/* Personal List Modal */}
          {showNewPLModal && (
            <NewPersonalListModal
              onClose={() => {
                setShowNewPLModal(false);
              }}
            />
          )}
          {/* Tooltip Modal */}
          {showTooltipModal && (
            <ToolTipModal
              definition={toolTipDefinition}
              modalButtonText={modalTextObject?.coreValueDirectoryModalButtonText ?? CoreStrings.okay}
              onClose={() => {
                setShowToolTipModal(null);
                toolTipDefinition = "";
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CVList;
